.checkbox {
  display: block;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 18px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .checkbox-checkmark {
      background-color: $primary;
      border-color: $primary;
    }
    &:checked ~ .checkbox-checkmark::after {
      opacity: 1;
    }
  }

  &.disabled {
    cursor: not-allowed;
  }

  &-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1.5px solid $text-secondary;
    transition: background 0.25s ease-in-out;
    border-radius: 6px;
    &::after {
      position: absolute;
      content: "";
      left: 6px;
      top: 4px;
      width: 4px;
      height: 8px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      opacity: 0;
    }
  }

  &:hover input ~ .checkbox-checkmark {
    border-color: $primary;
  }
}

.ant-switch-checked {
  background-color: $primary !important;
}
