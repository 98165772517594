.input {
  border: 1px solid rgba(7, 3, 16, 0.1);
  border-radius: 12px;
  padding: 1rem;
  outline: none;
  transition: border-color 0.25s ease-in-out;
  width: 100%;

  &::placeholder {
    color: #878893;
    font-style: italic;
  }
  &:focus {
    border-color: $primary;
  }

  &-have-icon {
    padding-right: 54px;
  }

  &-have-prefix {
    padding-left: 54px;
  }

  &-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
  }
  &-prefix {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
    font-size: 15px;
  }
}
