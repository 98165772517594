$primary: #ef6aaa;
$text: #070310;
$text-secondary: #878893;
$line: #f3f2f2;
$red: #eb5757;
$blue: #2f80ed;
$orange: #f2994a;
$green: #27ae60;
$gray: #434049;
$light-gray: #f3f3f3;

$border: rgba(7, 3, 16, 0.1);

$white: #ffffff;
$black: #000000;
$bg_400: #999999;
$bg_500: #e0e0e0;
$bg_600: #2d334d;

$background: #0a0a0a;
$background_20: #fafafa;
$background_50: #1f2023;
$background_100: #878893;
$background_150: #070310;
$background_200: #c6c8d2;
$background_250: #131620;
$background_300: #a4a8b7;
$background_350: #3c4467;
$background_400: #e0e0e0;
$background_450: #2d334d;
$background_500: #999999;
$background_550: #e2e4e9;
$background_600: #7f859f;
$background_650: #f5f5f5;
$background_700: #4d4d4d;
$background_750: #e2e2e2;
$background_800: #666666;
$background_900: #333333;
$background_950: #313131;

$white: #ffffff;
$white_50: #f3f2f3;
$white_100: #f0f0f0;

$black: #000000;

$primary_100: #f8ece1;
$primary_200: #9b6a3c;
$primary_500: #ef6aaa;
$primary_600: #7f75b1;

$green: #3cd48d;
$green_20: #20b352;

$violet: #6101eb;

$red: #ff4343;

$gradient: linear-gradient(273deg, #e3abac -14.98%, #736fb2 106.77%),
  linear-gradient(0deg, #edbe92 0%, #edbe92 100%), rgba(97, 1, 235, 0.1);
