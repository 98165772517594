@function font-calc($size) {
  @return $size / 16;
}

$fontSizes: 12, 14, 18, 24, 28;
@each $size in $fontSizes {
  .text-#{$size} {
    $fontSize: font-calc($size) + rem;
    font-size: $fontSize;
  }
}

$aligns: left, center, right;
@each $align in $aligns {
  .text-#{$align} {
    text-align: $align;
  }
}

$weights: 400, 500, 700;
@each $weight in $weights {
  .text-#{$weight} {
    font-weight: $weight;
  }
}

$line-heights: 18, 22, 36;
@each $line-height in $line-heights {
  .line-height-#{$line-height} {
    line-height: $line-height + px;
  }
}

.text-primary {
  color: $primary;
}

.text-secondary {
  color: $text-secondary;
}

.text-green {
  color: $green;
}

.text-black {
  color: $text;
}
.text-gray {
  color: $gray;
}
.text-red {
  color: $red !important;
}

.text-italic {
  font-style: italic;
}

.test-underline {
  text-decoration: underline;
}

.opacity-50 {
  opacity: 0.5;
}

.text-hover {
  &:hover {
    color: $primary !important;
  }
}
