.loading {
  position: fixed;
  overflow: hidden;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.75);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > .text {
    font-size: 24px;
    line-height: 1.5;
    color: white;
    letter-spacing: 5px;
  }
}
