.btn {
  background: transparent;
  border: none;
  box-shadow: none;
  border-radius: 8px;
  padding: 12px 16px;
  cursor: pointer;

  &-primary {
    background: $primary;
    color: $white;
    border: 1px solid $primary;

    &:hover {
      color: $white !important;
    }
  }

  &-outline {
    border: 1px solid $primary;
    color: $primary;

    &:hover {
      color: $white;
      background-color: $primary;
    }
  }

  &-outline-secondary {
    border: 1px solid $text-secondary;
    color: $text-secondary;
  }

  &-small {
    padding: 8px 24px;
  }
  &-disabled {
    background-color: $light-gray;
    border: none;
    color: $text;
    cursor: not-allowed;
  }
}
