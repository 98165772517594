html {
  font-size: 16px;
  * {
    font-family: "Inter", sans-serif;
  }
  p,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $text;
  }
}

.page {
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-40 {
  width: 40%;
}

.w-30 {
  width: 30%;
}

.h-100 {
  height: 100%;
}

.d-flex {
  display: flex;
}

.flex-h {
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-start {
  align-items: flex-start;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-space-between {
  justify-content: space-between;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.card {
  background: white;
  border: 1px solid $line;
  box-shadow: 0px 24px 64px -8px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
}

.p-fixed {
  position: fixed;
}

.p-relative {
  position: relative;
}

.text-underline {
  text-decoration: underline;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-white {
  background-color: $white;
}

.bg-grey {
  background-color: $light-gray;
}

.text-right {
  text-align: right;
}

.link {
  cursor: pointer;
  color: #edbe92 !important;
}

.w-max {
  width: max-content;
}

.flex-wrap {
  flex-wrap: wrap;
}

.overflow-hidden {
  overflow: hidden;
}

.hidden {
  display: none;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.ant-modal-footer > .ant-btn-dangerous {
  background: #ff4d4f;
  border-color: #ff4d4f;

  & > span {
    color: white;
  }

  &:hover {
    background: #ff4d4f;
    border-color: #ff4d4f;
  }
}

.relative {
  position: relative;
}

p {
  margin-bottom: 0 !important;
}
