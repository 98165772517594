.confirm_modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 12px;

  .title {
    font-size: 20px;
    color: #1b1b1c;
    font-weight: bold;
  }

  .description {
    text-align: center;
    font-size: 14px;
    color: #2d334d;
  }

  .btns {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 24px;
    column-gap: 12px;
  }
}

.ant-modal-content {
  border-radius: 12px;
}
