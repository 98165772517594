@for $i from 0 through 10 {
  // 20211013 DanhVt add spacing 0
  .m-#{$i} {
    margin: calc(8px * #{$i}) !important;
  }
  .mx-#{$i} {
    margin-left: calc(8px * #{$i}) !important;
    margin-right: calc(8px * #{$i}) !important;
  }
  .my-#{$i} {
    margin-top: calc(8px * #{$i}) !important;
    margin-bottom: calc(8px * #{$i}) !important;
  }
  .mt-#{$i} {
    margin-top: calc(8px * #{$i}) !important;
  }
  .mr-#{$i} {
    margin-right: calc(8px * #{$i}) !important;
  }
  .mb-#{$i} {
    margin-bottom: calc(8px * #{$i}) !important;
  }
  .ml-#{$i} {
    margin-left: calc(8px * #{$i}) !important;
  }

  .p-#{$i} {
    padding: calc(8px * #{$i}) !important;
  }
  .px-#{$i} {
    padding-left: calc(8px * #{$i}) !important;
    padding-right: calc(8px * #{$i}) !important;
  }
  .py-#{$i} {
    padding-top: calc(8px * #{$i}) !important;
    padding-bottom: calc(8px * #{$i}) !important;
  }
  .pt-#{$i} {
    padding-top: calc(8px * #{$i}) !important;
  }
  .pr-#{$i} {
    padding-right: calc(8px * #{$i}) !important;
  }
  .pb-#{$i} {
    padding-bottom: calc(8px * #{$i}) !important;
  }
  .pl-#{$i} {
    padding-left: calc(8px * #{$i}) !important;
  }
}
